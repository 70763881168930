import React from "react";
import Layout from "../components/Layout";
import Heading from "../components/Heading";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/SEO";
import { Helmet } from "react-helmet";

export default function PostsDetails({ pageContext }) {
  const {
    data: { node },
  } = pageContext;
  const body = JSON.parse(node.body.raw);

  return (
    <Layout>
      <Helmet>
        <title>{node.title} | RöDigital </title>
      </Helmet>
      <Heading
        title={node.title}
        subtitle={node.excerpt.excerpt}
        image={node.featuredImage.gatsbyImageData}
        alt={node.slug}
      />
      <section className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="blog-detail-item">

                  <div className="blog-item-content">
                    <p className="category third-color d-flex">
                      <p className="third-color tex">
                        {node.tags.map((tag) => (
                          <span className="category text-capitalize" key={tag}>
                            {tag}
                          </span>
                        ))}
                      </p>
                      <span className="mx-1"> - </span>
                      <p className="date text-capitalize">{node.publishDate}</p>
                    </p>
                    <h1 className="mt-2 mb-4">{node.title}</h1>

                    <div className="blog-item-img">
                      <GatsbyImage
                        image={node.featuredImage.gatsbyImageData}
                        alt={node.featuredImage.title}
                        aspectRatio={16 / 9}
                        format="fixed"
                        width={800}
                        height={500}
                      />
                    </div>
                    <div
                      className="content-text"
                      dangerouslySetInnerHTML={{
                        __html: documentToHtmlString(body),
                      }}
                    />
                  </div>
                
                <div className="col-lg-4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const Head = ({ pageContext }) => {
  const {
    data: { node },
  } = pageContext;

  return (
    <Seo
      title={node.title}
      description={node.excerpt.excerpt}
      metaImage={node.featuredImage.gatsbyImageData}
      metaSiteUrl={`https://rodigital.com.ar/${node.slug}`}
    />
  );
};
